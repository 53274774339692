import { Controller } from "@hotwired/stimulus";
import { Tooltip } from "bootstrap";

export default class extends Controller {
  static targets = ["password", "group", "passwordConfirmation", "groupConfirmation", "submitButton"];

  connect() {
    this.tooltip = new Tooltip(this.groupTarget, {
      html: true,
      customClass: "password-tooltip",
      placement: "right",
      trigger: "focus hover",
      container: "body",
      title: this.generatePasswordRulesContent(),
    });

    this.groupTarget.addEventListener('shown.bs.tooltip', () => {
      this.tooltipElement = document.querySelector(".tooltip-inner");
      this.updatePassword();
    });

    this.confirmationTooltip = new Tooltip(this.groupConfirmationTarget, {
      html: true,
      customClass: "password-tooltip",
      placement: "right",
      trigger: "focus hover",
      container: "body",
      title: this.generatePasswordMatchContent(),
    });

    this.groupConfirmationTarget.addEventListener('shown.bs.tooltip', () => {
      this.tooltipElement = document.querySelector(".tooltip-inner");
      this.updatePasswordConfirmation();
    });

    this.updateSubmitButtonState();
  }

  updatePassword() {
    this.checkPasswordRules();
    this.checkPasswordMatch();
    this.updateSubmitButtonState();
  }

  updatePasswordConfirmation() {
    this.checkPasswordMatch();
    this.updateSubmitButtonState();
  }

  checkPasswordRules() {
    const password = this.passwordTarget.value;

    const longPassword = password.length > 16;

    if (longPassword) {
      this.passwordValid = true;
      this.showOnlyLongPasswordRule();
    } else {
      const rules = {
        length: password.length >= 8,
        digit: /\d/.test(password),
        lower: /[a-z]/.test(password),
        upper: /[A-Z]/.test(password),
        special: /[@#$%&+.,!?~*{}]/.test(password),
      };
  
      Object.entries(rules).forEach(([key, valid]) => {
        const icon = document.querySelector(`#icon-${key}`);
        if (icon) {
          icon.parentElement.classList.remove('d-none');
          icon.classList.toggle("bi-check-lg", valid);
          icon.classList.toggle("text-success", valid);
          icon.classList.toggle("bi-x-lg", !valid);
          icon.classList.toggle("text-danger", !valid);
        }
      });
  
      const longPasswordItem = document.querySelector('#icon-long');
      if (longPasswordItem) {
        longPasswordItem.parentElement.classList.add('d-none');
        this.tooltip.update();
      }
  
      this.passwordValid = Object.values(rules).every(Boolean);
    }
  }

  updateTooltipContent() {
    if (this.tooltip) {
      if (this.longPassword) {
        this.tooltip._config.title = this.generateLongPasswordContent();
      } else {
        this.tooltip._config.title = this.rulesContent;
      }

      if (this.tooltip.tip && this.tooltip.tip.querySelector('.tooltip-inner')) {
        this.tooltip.tip.querySelector('.tooltip-inner').innerHTML = this.tooltip._config.title;
      }
    }
  }
  

  showOnlyLongPasswordRule() {
    const ruleItems = document.querySelectorAll('#passwordRules li');
    ruleItems.forEach(item => {
      item.classList.add('d-none');
    });
  
    const longPasswordItem = document.querySelector('#icon-long');
    if (longPasswordItem) {
      longPasswordItem.parentElement.classList.remove('d-none');
      longPasswordItem.classList.add("bi-check-lg", "text-success");
      longPasswordItem.classList.remove("bi-x-lg", "text-danger");
        this.tooltip.update();
    }
  }

  checkPasswordMatch() {
    const password = this.passwordTarget.value;
    const confirmation = this.passwordConfirmationTarget.value;

    this.passwordsMatch = password === confirmation && password.length > 0;

    const icon = document.querySelector(`#icon-match`);
    const text = document.querySelector(`#text-match`);
    if (icon) {
      icon.classList.toggle("bi-check-lg", this.passwordsMatch);
      icon.classList.toggle("text-success", this.passwordsMatch);
      icon.classList.toggle("bi-x-lg", !this.passwordsMatch);
      icon.classList.toggle("text-danger", !this.passwordsMatch);
      text.textContent = this.passwordsMatch ? 'Hesla se shodují' : 'Hesla se neshodují';
    }
  }

  updateSubmitButtonState() {

    if (!this.submitButtonTarget.classList.contains('submitting') && (this.passwordValid && this.passwordsMatch)) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }

  generatePasswordRulesContent() {
    return `
      <ul class='list-group' id='passwordRules'>
        <li class='list-group-item'>
          <i class='bi bi-x-lg text-danger' id='icon-length'></i>
          Má alespoň 8 znaků
        </li>
        <li class='list-group-item'>
          <i class='bi bi-x-lg text-danger' id='icon-digit'></i>
          Obsahuje číslici
        </li>
        <li class='list-group-item'>
          <i class='bi bi-x-lg text-danger' id='icon-lower'></i>
          Obsahuje malé písmeno
        </li>
        <li class='list-group-item'>
          <i class='bi bi-x-lg text-danger' id='icon-upper'></i>
          Obsahuje velké písmeno
        </li>
        <li class='list-group-item'>
          <i class='bi bi-x-lg text-danger' id='icon-special'></i>
          Obsahuje speciální znak (!@#$..)
        </li>
        <li class='list-group-item d-none tooltip-single-item'>
          <i class='bi bi-x-lg text-danger' id='icon-long'></i>
          Obsahuje více než 16 znaků
        </li>
      </ul>
    `;
  }

  generatePasswordMatchContent() {
    return `
      <ul class='list-group' id='passwordMatch'>
        <li class='list-group-item'>
          <i class='bi bi-x-lg text-danger' id='icon-match'></i>
          <span id='text-match'>Hesla se neshodují</span>
        </li>
      </ul>
    `;
  }
}