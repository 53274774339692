import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  disableButtonWithSpinner(event) {
    event.preventDefault();

    const button = this.buttonTarget;
    button.classList.add('submitting');
    button.disabled = true;

    this.originalContent = button.innerHTML;
    button.innerHTML = `
      <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
      ${this.originalContent}
    `;
    button.form.submit();
  }
}